<template>
  <div class="outer-page">
    <div class="center-width">
      <div class="content">
        <div class="job-list">
          <ul>
            <li v-for="(item, index) in data" :key="index">
              <div class="job-title">
                <h6>{{item.jobTitle}}</h6>
                <span>发布日期：{{item.orderDate.split(" ")[0]}}</span>
                <div class="clears"></div>
              </div>
              <div class="join-info">
                  <span v-html="item.jobRequire"></span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      data: [],
    };
  },
  methods: {
    getList() {
      this.$post("websitejob/list").then((res) => {
        this.data = res.list;
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.577rem;
  line-height: 1rem;
  .center-width {
    width: 94%;
    margin: 0 3%;
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      //   padding-top: 0.5rem;
      .job-list {
        ul {
          list-style: none;
          li {
            border: 1px #d9d9d9 solid;
            margin-bottom: 0.5rem;
            .job-title {
              height: 1.3rem;
              line-height: 1.3rem;
              padding: 0 0.5rem;
              border-bottom: 1px #d9d9d9 solid;
              h6 {
                float: left;
                min-width: 3.777rem;
              }
              span {
                float: left;
                color: #999999;
              }
              .clears {
                clear: both;
                line-height: 0;
                overflow: hidden;
                font-size: 0;
                height: 0;
              }
            }
            .join-info {
              padding: 0.5rem 0.5rem 2rem;
              position: relative;
            }
          }
        }
      }
    }
  }
}
</style>